import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import {
    MessageCheckIcon,
    RepeatIcon,
    SettingsIcon,
    StackedIcon,
    TerminalIcon,
} from "./Icons"

import backgroundImage from "../../assets/BackgroundImages/UpgradeGuide/background1.png"

type WhatToExpectItemProps = {
    title: string
    description: string
    icon?: React.ReactNode
}

const WhatToExpectItem = ({
    title,
    description,
    icon,
}: WhatToExpectItemProps) => {
    return (
        <div className="flex flex-col lg:flex-row p-8 lg:pl-9 lg:pr-28 lg:py-11 border border-horizen-content-grey lg:items-center">
            <div className="w-[25px] h-[25px] mr-11">{icon}</div>
            <span className="text-[22px] leading-7 font-bold lg:w-64 my-2 lg:my-0">
                {title}
            </span>
            <p className="lg:ml-24 lg:text-lg flex-1">{description}</p>
        </div>
    )
}

const WhatToExpect = () => {
    const WhatToExpectItems: WhatToExpectItemProps[] = [
        {
            title: "Seamless Upgrade Process",
            description:
                "The upgrade process is designed to be smooth and seamless, ensuring minimal disruption to your activities.",
            icon: <SettingsIcon />,
        },
        {
            title: "Comprehensive Snapshots",
            description:
                "Comprehensive state snapshots of both Horizen mainchain and EON sidechain will be taken to ensure a seamless upgrade to Horizen 2.0.",
            icon: <TerminalIcon />,
        },
        {
            title: "Backward Compatibility",
            description:
                "Your existing ZEN holdings and EON applications will migrate smoothly, including incentives, emissions, staking, integrations, and exchange continuity.",
            icon: <RepeatIcon />,
        },
        {
            title: "Guided Mainchain ZEN Claim Process",
            description:
                "There will be a claim process through a claiming mechanism over time that is designed to prioritize both security and ease of use for all users.",
            icon: <StackedIcon />,
        },
        {
            title: "Community Support",
            description:
                "Continuous support and updates will be provided throughout the upgrade process to address any questions or concerns you may have.",
            icon: <MessageCheckIcon />,
        },
    ]
    return (
        <section
            className="pt-[122px] lg:pt-[270px] bg-[center_-210px] bg-contain bg-no-repeat"
            style={{
                backgroundImage: `url(${backgroundImage})`,
            }}
        >
            <ContentContainer>
                <span
                    className="font-bold max-w-[454px] inline-block
                    text-[36px] leading-10
                    lg:text-[50px] lg:leading-[55px]"
                >
                    What to Expect During the Upgrade
                </span>
                <div className="space-y-3 pt-14">
                    {WhatToExpectItems.map((item) => (
                        <WhatToExpectItem
                            key={item.title}
                            title={item.title}
                            description={item.description}
                            icon={item.icon}
                        />
                    ))}
                </div>
            </ContentContainer>
        </section>
    )
}

export default WhatToExpect
