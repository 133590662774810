import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../templates/Layout"
import WhatToExpect from "../components/UpgradeGuide/WhatToExpect"
import RecommendedActions from "../components/UpgradeGuide/RecommendedActions"
import StayInformed from "../components/Horizen2.0/StayInformed"
import NewsletterForm from "../components/NewsletterForm"
import NormalPageHero from "../components/NormalPageHero"

import * as styles from "../components/UpgradeGuide/hero.module.css"
import LatestUpdatesBackground from "../assets/LatestUpdatesBackground.png"

const UpgradeGuide = () => {
    return (
        <Layout>
            <Helmet>
                <title>Horizen 2.0 Upgrade Community Guide</title>
                <meta
                    property="og:title"
                    content="Horizen 2.0 Upgrade Community Guide"
                />
                <meta
                    property="og:image"
                    content="https://horizen.io/assets/images/meta/upgradeGuide.jpg"
                />
                <meta
                    name="description"
                    content="Everything you need to know to prepare for the Horizen 2.0 upgrade"
                />
                <meta
                    property="og:description"
                    content="Everything you need to know to prepare for the Horizen 2.0 upgrade"
                />
                <meta
                    name="twitter:title"
                    content="Horizen 2.0 Upgrade Community Guide"
                />
                <meta
                    name="twitter:description"
                    content="Everything you need to know to prepare for the Horizen 2.0 upgrade"
                />
                <meta
                    name="twitter:image"
                    content="https://horizen.io/assets/images/meta/upgradeGuide.jpg"
                />
            </Helmet>
            <main className="bg-horizen-hp-bg text-white">
                <div
                    className={`pt-[9rem] md:pt-[calc(4rem+150px)] lg:pb-48 ${styles.upgradeGuideHero}`}
                >
                    <NormalPageHero titleKey="upgradeGuide.hero.title" />
                </div>
                <div className="lg:-mt-24">
                    <RecommendedActions />
                </div>
                <WhatToExpect />
                <div
                    className="bg-cover bg-no-repeat bg-center"
                    style={{
                        backgroundImage: `url(${LatestUpdatesBackground})`,
                    }}
                >
                    <StayInformed
                        gaEventDetails={{
                            joinCommunity: "2_0_guide_page_join_community",
                            followX: "2_0_guide_page_follow_x",
                        }}
                    />
                    <div className="pt-40 lg:pt-[248px]">
                        <NewsletterForm />
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default UpgradeGuide
