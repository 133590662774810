import React, { useEffect } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"
import ContentContainer from "../../templates/ContentContainer"

type RecommendedActionsItemProps = {
    title: string
    description: string | JSX.Element
}

const RecommendedActionsItem = ({
    title,
    description,
}: RecommendedActionsItemProps) => {
    return (
        <div className="group flex flex-col lg:flex-row p-8 lg:pl-9 lg:pr-28 lg:pb-8 relative RecommendedActionsItem">
            <span className="pl-4 lg:pl-0 text-[20px] leading-[30px] font-bold lg:w-48 lg:text-right whitespace-pre-wrap">
                {title}
            </span>
            <p className="lg:ml-24 lg:text-lg flex-1">{description}</p>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                className="absolute left-0 lg:left-[calc(228px+40.5px)] top-10 z-10 bullet"
            >
                <circle cx="7.5" cy="7.5" r="7.5" fill="#0D3D85" />
            </svg>
            <div
                className="border-l border-[#9EA1AE] group-last:hidden h-full
                absolute top-10 left-[7px] lg:left-[calc(228px+47.5px)]"
            />
        </div>
    )
}

const RecommendedActions = () => {
    const RecommendedActionsItems: RecommendedActionsItemProps[] = [
        {
            title: "Secure Your ZEN",
            description:
                "Ensure you have a secure backup of your wallet and private key to safeguard and access your ZEN. ZEN holders will be able to claim any ZEN existing on the Horizen mainchain through a guided claiming process.",
        },
        {
            title: "Move your ZEN\nto EON",
            description: (
                <p>
                    ZEN currently on EON will automatically be available to
                    users on Horizen 2.0 using the same wallet address. For
                    simpler migration, it’s advised to forward transfer ZEN from
                    the Horizen mainchain to EON.{" "}
                    <a
                        className="underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://docs.horizen.io/horizen_eon/connect/forward_transfer/"
                    >
                        See this tutorial for forward transfer instructions.
                    </a>
                </p>
            ),
        },
        {
            title: "Stake Your ZEN on EON",
            description:
                "Stake your ZEN on EON to keep the rewards rolling during the 2.0 upgrade. Staking through a staking provider is an easy and quick way for non-techies to participate, but always DYODD and pick a trusted provider.",
        },
        {
            title: "Prepare to Spin Up Collator Nodes",
            description:
                "Once the comprehensive state snapshots of both Horizen mainchain and EON sidechain are taken, Horizen Super Nodes and EON Forger Nodes will be deprecated. Users who want to participate in the network will be able to do so by setting up collator nodes. Detailed instructions will be provided.",
        },
        {
            title: "Participate in the Prospective zkVerify Token Claim",
            description:
                "Eligible ZEN holders are anticipated to receive some allocation of the initial zkVerify token supply when zkVerify goes to mainnet. Details are TBD and forthcoming.",
        },
        {
            title: "Stay Informed",
            description:
                "Subscribe to updates and follow the official Horizen social media and community channels to receive the latest and the most accurate updates.",
        },
    ]

    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        const q = gsap.utils.selector("#RecommendedActionsItemsContainer")

        q(".RecommendedActionsItem").forEach((listItem) => {
            const toggleClasses = () => {
                listItem
                    .querySelector(".bullet")
                    ?.classList.toggle("drop-shadow-triangle")
            }

            const bulletCircle = listItem.querySelector("circle")

            gsap.timeline({
                scrollTrigger: {
                    trigger: listItem,
                    start: `center-=${listItem.clientHeight} center-=200px`,
                    end: `center+=${listItem.clientHeight} center-=200px`,
                    scrub: 1,
                    onEnter: toggleClasses,
                    onLeaveBack: toggleClasses,
                    onLeave: toggleClasses,
                    onEnterBack: toggleClasses,
                },
            })
                .to(bulletCircle, {
                    fill: "#0E9DE5",
                })
                .to(bulletCircle, {
                    fill: "#0D3D85",
                })
        })
    }, [])

    return (
        <section>
            <ContentContainer>
                <p
                    className="font-bold w-80 lg:w-[550px]
                    text-4xl leading-10
                    lg:text-[50px] lg:leading-[55px]
                "
                >
                    Recommended Actions for ZEN Community
                </p>
                <div
                    className="mt-11 relative"
                    id="RecommendedActionsItemsContainer"
                >
                    {RecommendedActionsItems.map((item) => (
                        <RecommendedActionsItem
                            key={item.title}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </div>
            </ContentContainer>
        </section>
    )
}

export default RecommendedActions
